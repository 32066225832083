import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchNotifications: (ctx, q) => axiosIns.get(`/api.notification/notification${q || ''}`),
    fetchNotification: (ctx, { id }) => axiosIns.get(`/api.notification/notification/${id}`),
    countUnread: () => axiosIns.get('/api.notification/notification/count-unread'),
    makeRead: (ctx, { id }) => axiosIns.put(`/api.notification/notification/${id}/read`),
    makeAllRead: () => axiosIns.put('/api.notification/notification/read-all'),
  },
}
