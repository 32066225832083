export default [
  // nhà phố
  {
    header: 'Chiến dịch',
    action: 'read',
    resource: 'Campaign',
  },
  {
    title: 'Danh sách',
    route: 'campaign-list',
    icon: 'ListIcon',
    action: 'list',
    resource: 'Campaign',
  },
  {
    title: 'Tạo mới',
    route: 'campaign-create',
    icon: 'CrosshairIcon',
    action: 'create',
    resource: 'Campaign',
  },
]
