<template>
  <div>
    <b-nav-item-dropdown
      v-if="userData && userData.fullname"
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav minw-110">
          <p class="user-name font-weight-bolder mb-25">
            {{ userData.fullname || userData.username }}
          </p>
          <span
            v-if="userData.role"
            class="user-status text-primary"
          >{{ ROLES.find(item => item.value === userData.role).text }}</span>
        </div>
        <b-avatar
          size="40"
          :src="getUrlFile(userData.avatar, today)"
          variant="light-primary"
          badge
          class="avatar-border-2 box-shadow-1 badge-minimal user-avatar"
          badge-variant="success"
        >
          <feather-icon
            v-if="!userData.fullname"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>

      <b-dropdown-item
        v-if="ability().can('manage', 'Account')"
        link-class="d-flex align-items-center"
        @click="goMyAccount"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Trang cá nhân</span>
      </b-dropdown-item>

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="openModalChangePassword"
      >
        <feather-icon
          size="16"
          icon="AlertTriangleIcon"
          class="mr-50"
        />
        <span>Đổi mật khẩu</span>
      </b-dropdown-item>

      <b-dropdown-divider />

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Đăng xuất</span>
      </b-dropdown-item>

      <modal-change-password
        id="modal-user-change-password"
        ref="modal-user-change-password"
        modal-mode="user-change-password"
        :user="userData"
      />
    </b-nav-item-dropdown>
    <b-button
      v-else
      variant="flat-danger"
      @click="logout"
    >
      Đăng xuất
    </b-button>

    <!-- modal -->
    <b-modal
      id="modal-user-change-avatar"
      ref="modal-user-change-avatar"
      title="Đổi ảnh đại diện"
      ok-only
      ok-title="Cập nhật"
      no-stacking
      @ok="handleUpdateUser"
    >
      <label>Ảnh đại diện:</label>
      <div class="flex-grow-1 mb-1">
        <b-avatar
          size="100"
          class="avatar-border-2 box-shadow-1 badge-minimal"
          :src="getUrlFile(userData.avatar, today)"
          rounded
          variant="light-primary"
          alt="Ảnh đại diện"
        >
          <feather-icon
            v-if="!userData.avatar"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </div>
      <div class="mb-1">
        <uploads
          id="uploads"
          ref="uploads"
          class="hidden-all-item upload-image"
          :files="files"
          :options="{textDisplay: 'Tải ảnh lên'}"
          @file-uploaded="handleUploaded"
          @file-removed="handleRemoved"
          @all-done="handleAvatarUploaded"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar, BDropdownDivider, BDropdownItem, BNavItemDropdown, BButton,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ModalChangePassword from '@/views/settings/users/list/ModalChangePassword.vue'
import Uploads from '@core/components/uploads/Uploads.vue'
import http from '@/global/http'
import store from '@/store'
import ability from '@/libs/acl/ability'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    ModalChangePassword,
    BButton,
    Uploads,
  },
  data() {
    return {
      userData: {},
      avatarText,
      today: new Date().toISOString(),
      files: [],
    }
  },
  watch: {
    files(n) {
      if (!n && !n.length) return
      const last = n[n.length - 1] || {}
      this.userData.avatar = last.origin || ''
    },
  },
  created() {
    this.userData = this.$getItem('userData') || {}
  },
  methods: {
    ability,
    handleUpdateUser(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$call(http.handle(store.dispatch('user/updateUser', this.userData), () => {
        this.$refs['modal-user-change-avatar'].hide()
      }), true)
    },
    handleUploaded(files = []) {
      this.files.push(...files)
    },
    goMyAccount() {
      this.$router.push({ name: 'account' })
    },
    handleRemoved(file) {
      const foundIdx = this.files.findIndex(f => f.name === file.name)
      if (foundIdx !== -1) {
        this.files.splice(foundIdx, 1)
      }
    },
    handleAvatarUploaded() {
    },
    openModalChangePassword() {
      this.$refs['modal-user-change-password'].$refs['btn-open-modal-change-password'].click()
    },
    openModalChangeAvatar() {
      this.$refs['modal-user-change-avatar'].show()
    },
  },
}
</script>
