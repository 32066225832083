export default [
  // nhà phố
  {
    header: 'Nhà phố, KS, CHDV',
    action: 'read',
    resource: 'ProductCornerTitle',
  },
  {
    title: 'Sản phẩm thuê',
    icon: 'FeatherIcon',
    tagFromStore: 'expireHiredProduct',
    tagVariant: 'light-warning',
    resource: 'ProductCornerHiredTitle',
    children: [
      {
        title: 'Tìm kiếm',
        route: 'product-hired-list',
        action: 'list',
        resource: 'ProductHired',
      },
      {
        title: 'Quản lý',
        route: 'product-hired-manage',
        action: 'read',
        resource: 'ProductHiredManage',
      },
      {
        title: 'Sắp hết hạn',
        route: 'product-expire-list',
        tagFromStore: 'expireHiredProduct',
        tagVariant: 'light-warning',
        action: 'list',
        resource: 'ProductExpire',
      },
      {
        title: 'Tạo mới',
        route: 'product-hired-create',
        action: 'create',
        resource: 'ProductHired',
      },
    ],
  },
  {
    title: 'Sản phẩm bán',
    resource: 'ProductCornerSellTitle',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Tìm kiếm',
        route: 'product-sell-list',
        action: 'list',
        resource: 'ProductSell',
      },
      {
        title: 'Quản lý',
        route: 'product-sell-manage',
        action: 'read',
        resource: 'ProductSellManage',
      },
      {
        title: 'Tạo mới',
        route: 'product-sell-create',
        action: 'create',
        resource: 'ProductSell',
      },
    ],
  },
  {
    title: 'Khách hàng',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Tìm kiếm',
        route: 'customer-corner-list',
        action: 'list',
        resource: 'CustomerCorner',
      },
      {
        title: 'Quản lý',
        route: 'customer-corner-manage',
        action: 'read',
        resource: 'CustomerCornerManage',
      },
      {
        title: 'Tạo mới',
        route: 'customer-corner-create',
        action: 'create',
        resource: 'CustomerCorner',
      },
    ],
  },
]
