export default [
  {
    header: 'Báo cáo',
    action: 'read',
    resource: 'ReportTitle',
  },
  {
    title: 'Nhân viên',
    route: 'report-list',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'Report',
  },
  {
    title: 'Báo cáo tổng hợp',
    route: 'report-general',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'ReportGeneral',
  },
  {
    title: 'Báo cáo khách hàng',
    route: 'report-client',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'ReportGeneral',
  },
  {
    title: 'Báo cáo tổng quan',
    route: 'report-overview',
    icon: 'BarChart2Icon',
    action: 'read',
    resource: 'ReportOverview',
  },
]
