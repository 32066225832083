/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import overview from './overview'
import notification from './notification'
import loginRequest from './login-request'
import campaign from './campaign'
import productHotel from './product-hotel'
import productCorner from './product-corner'
import productOffice from './product-office'
import users from './user'
import careers from './careers'
import reports from './reports'

const menu = [
  ...overview,
  ...notification,
  ...loginRequest,
  ...campaign,
  // ...productHotel,
  ...productCorner,
  ...productOffice,
  ...careers,
  ...reports,
  ...users,
]

export default menu.filter(item => item)
