export default [
  // văn phòng
  {
    header: 'Văn phòng',
    action: 'read',
    resource: 'OfficeTitle',
  },
  {
    title: 'Sản phẩm (VP)',
    icon: 'FileTextIcon',
    tagFromStore: 'expireProductOffice',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Tìm kiếm',
        route: 'product-office-list',
        action: 'list',
        resource: 'ProductOffice',
      },
      {
        title: 'Quản lý',
        route: 'product-office-manage',
        action: 'read',
        resource: 'ProductOfficeManage',
      },
      {
        title: 'Sắp hết hạn',
        route: 'product-office-expire-list',
        tagFromStore: 'expireProductOffice',
        tagVariant: 'light-warning',
        action: 'list',
        resource: 'ProductOfficeExpire',
      },
      {
        title: 'Tạo mới',
        route: 'product-office-create',
        action: 'create',
        resource: 'ProductOffice',
      },
    ],
  },
  {
    title: 'Khách hàng (VP)',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Tìm kiếm',
        route: 'customer-office-list',
        action: 'list',
        resource: 'CustomerOffice',
      },
      {
        title: 'Quản lý',
        route: 'customer-office-manage',
        action: 'read',
        resource: 'CustomerOfficeManage',
      },
      {
        title: 'Tạo mới',
        route: 'customer-office-create',
        action: 'create',
        resource: 'CustomerOffice',
      },
    ],
  },
]
