export default [
  {
    header: 'Ngành nghề',
    action: 'read',
    resource: 'CareerTitle',
  },
  {
    title: 'Danh sách',
    route: 'career-list',
    icon: 'BriefcaseIcon',
    action: 'list',
    resource: 'Career',
  },
]
