export default [
  {
    header: 'Nhân viên',
    action: 'read',
    resource: 'UserTitle',
  },
  {
    title: 'Danh sách',
    route: 'user-list',
    icon: 'UsersIcon',
    action: 'list',
    resource: 'User',
  },
  {
    title: 'Cá nhân',
    route: 'account',
    icon: 'UserIcon',
    resource: 'Account',
  },
]
