export default [
  {
    title: 'Tổng quan',
    icon: 'HomeIcon',
    route: 'overview',
    action: 'read',
    resource: 'Overview',
  },
  {
    title: 'Check product',
    icon: 'InfoIcon',
    route: 'check-product-list',
    action: 'read',
    resource: 'CheckProduct',
  },
]
