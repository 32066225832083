<template>
  <b-nav-item-dropdown
    ref="dropdown-notification"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="totalNotis"
        badge-classes="bg-danger"
        class="text-body"
        :class="totalNotis ? 'bell-animation' : ''"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <div class="dropdown-menu-header custom-border-top-1px">
      <div class="dropdown-header d-flex justify-content-between">
        <h4 class="notification-title mb-0">
          Thông báo chưa đọc
        </h4>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          :disabled="!canMakeAllRead"
          @click="makeAllRead()"
        >Đánh dấu đã đọc
        </b-button>
      </div>
    </div>
    <!-- Notifications -->
    <div
      v-if="totalNotis"
      class="dropdown-scroll"
    >
      <div
        v-for="n in notifications"
        :key="'noti-' + n.id"
        class="p-50 hover-bg-gains position-relative border-bottom"
        @click="e => handleMakeRead(n, e)"
      >
        <b-media class="font-small-3">
          <div class="d-flex justify-content-between mb-50 text-muted">
            <small>Sản phẩm: <strong>#{{ n.product_id }}</strong></small>
            <small>Khách hàng: <strong>#{{ n.client_id }}</strong></small>
          </div>
          <p class="media-heading d-flex justify-content-between">
            <span>
              Sản phẩm
              <router-link
                class="font-weight-bolder"
                :class="n.status === 'unread' ? 'text-primary' : 'text-secondary'"
                :to="'/product-' + (n.meta.product_type === 'ban' ? 'sell/' : 'hired/') + n.product_id + '/detail'"
              >
                {{ n.meta.product_title }}
              </router-link>
              phù hợp với
              <router-link
                class="font-weight-bolder"
                :class="n.status === 'unread' ? 'text-primary' : 'text-secondary'"
                :to="'/customer-' + (n.meta.customer_type === 'office' ? 'office/' : 'corner/') + n.client_id + '/detail'"
              >
                {{ n.meta.client_name }}
              </router-link>
            </span>
          </p>
        </b-media>
      </div>
    </div>

    <div
      v-else
      class="text-center pt-2 pb-1"
    >
      Bạn không có thông báo mới nào!
    </div>

    <b-button
      v-show="!hasFetchAll"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      block
      variant="flat-primary"
      @click="goNotifications"
    >
      {{ 'Xem tất cả' }}
    </b-button>
  </b-nav-item-dropdown>
</template>

<script>
import { BButton, BMedia, BNavItemDropdown } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import http from '@/global/http'
import store from '@/store'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import notificationStoreModule from './notificationStoreModule'

export default {
  components: {
    BNavItemDropdown,
    BMedia,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      timer: null,
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    this.fetchNotifications()
    // this.timer = setInterval(() => {
    //   this.fetchNotifications()
    // }, 120000)
  },
  methods: {
    goNotifications() {
      if (this.$route.name === 'notification-list') return
      this.$router.push({
        name: 'notification-list',
      })
    },
    handleMakeRead(c, e) {
      e.stopPropagation()
      this.makeRead(c)
    },
    goContractDetail(c) {
      const id = c.data.contract_id
      this.$refs['dropdown-notification'].$refs.toggle.$el.click()
      this.makeRead(c)
      this.$router.push({
        name: 'contracts-detail',
        params: { id },
        query: { t: Date.now() },
      })
    },
  },
  setup() {
    const NOTIFICATION_STORE_MODULE_NAME = 'notification'
    if (!store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.registerModule(NOTIFICATION_STORE_MODULE_NAME, notificationStoreModule)
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATION_STORE_MODULE_NAME)) store.unregisterModule(NOTIFICATION_STORE_MODULE_NAME)
    })

    const notifications = ref([])
    const totalNotis = ref(0)
    const notification = ref({})
    const unreadNoti = computed({
      get: () => notifications.value.filter(n => n.status === 'unread')?.length,
      set: () => {
      },
    })
    const hasFetchAll = ref(false)
    const canMakeAllRead = computed({
      get: () => unreadNoti.value,
      set: () => {
      },
    })

    const updateDocumentTitle = title => {
      if (title)document.title = title
      else if (totalNotis.value) {
        document.title = `(${totalNotis.value}) Connect Land`
      }
    }

    const fetchNotifications = () => {
      const queryString = http.buildQuery({
        page: 0,
        perpage: 999,
        status: 'unread',
      }) || ''
      return http.handle(store.dispatch('notification/fetchNotifications', queryString), res => {
        notifications.value = res?.data.filter(n => n.title) || []
        notifications.value.sort((a, b) => (a.status > b.status ? -1 : 1))
        totalNotis.value = res?.total || 0
        updateDocumentTitle()
      })
    }

    const fetchNotification = ({ id }) => http.handle(store.dispatch('notification/fetchNotification', { id }), res => {
      notification.value = res?.data || []
    })

    const countUnread = () => http.handle(store.dispatch('notification/countUnread'), () => {
    })

    const makeRead = ({ id }) => http.handle(store.dispatch('notification/makeRead', { id }), () => {
      const found = notifications.value.find(n => n.id === id)
      found.status = 'read'
      updateDocumentTitle()
    })

    const makeAllRead = () => http.handle(store.dispatch('notification/makeAllRead'), () => {
      for (let i = 0; i < notifications.value.length; i += 1) {
        notifications.value[i].status = 'read'
        totalNotis.value = 0
      }
      updateDocumentTitle()
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      hasFetchAll,
      totalNotis,
      canMakeAllRead,
      perfectScrollbarSettings,
      unreadNoti,
      fetchNotifications,
      fetchNotification,
      countUnread,
      makeRead,
      makeAllRead,
    }
  },
}
</script>

<style>
.check-read-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
